import axios from '@/common/axios'
import qs from 'qs'

export function addDept (data) {
  return axios({
    method: 'post',
    url: '/dept/add',
    data: qs.stringify(data)
  })
}

export function deleteDept (id) {
  return axios({
    method: 'delete',
    url: '/dept/delete/' + id
  })
}

export function updateDept (data) {
  return axios({
    method: 'put',
    url: '/dept/update',
    data: qs.stringify(data)
  })
}

export function selectDeptInfo (id) {
  return axios({
    method: 'get',
    url: '/dept/info/' + id
  })
}

export function selectDeptTree () {
  return axios({
    method: 'get',
    url: '/dept/tree'
  })
}
